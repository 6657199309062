import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  pages = {
    home: {name: 'Home', active: true, icon: 'fa fa-home'},
    board: {name: 'Board', active: false, icon: 'fa fa-users'},
    wards: {name: 'Wards', active: false, icon: 'fa fa-map'},
    weather: {name: 'Weather', active: false, icon: 'fa fa-cloud'},
    documents: {name: 'Documents', active: false, icon: 'fa fa-document'},
    links: {name: 'Links', active: false, icon: 'fa fa-cloud'},
    contacts: {name: 'Contacts', active: false, icon: 'fa fa-cloud'},
  };

  lastPage = 'home';

  pageKeys = ['home', 'board', 'wards', 'weather', 'contacts']

  constructor() {
  }

  selectPage(s: string) {

    this.pages[this.lastPage]['active'] = false;
    this.pages[s]['active'] = true;
    this.lastPage = s;

    const component = document.getElementById(s);

    component.scrollIntoView();

  }
}
