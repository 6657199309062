import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {

  staff = [
    {
      name: 'Andre Scheepers',
      image: 'assets/img/faces/Andre-Scheepers.png',
      position: 'Manager',
      details1: 'Cell: 083 310 7252',
      details2: 'Office: 013 752 6419'
    },
    {
      name: 'Ivina Alberts',
      image: 'assets/img/faces/Ivina-Alberts.png',
      position: 'Administrator',
      details1: 'Cell : 082 566 2728',
      details2: 'Office: 013 752 6419',
      email: 'admin@mufpa.co.za'
    },
    {
      name: 'Dispatch',
      image: 'assets/img/faces/Dispatch.png',
      position: '',
      details1: 'Cell : 082 388 2874',
      details2: 'Office: 0860 66 3473',
      email: 'admin@mufpa.co.za'
    },
    {
      name: 'Queen Khoza',
      image: 'assets/img/faces/Queen%20Khoza.jpg',
      position: 'Base Manager',
      details1: 'Cell : 082 388 2953',
      details2: 'Office: 0860 66 3473',
      email: 'base@mufpa.co.za'
    },
    {
      name: 'Nomcebo Mlotywa',
      image: 'assets/img/faces/Permit%20Officer.jpg',
      position: 'Permit Officer',
      details1: 'Emergency: 0860 66 3473',
      details2: 'Office: 013 752 6419',
      email: 'permit@mufpa.co.za'
    },
    {
      name: 'GIS',
      image: 'assets/img/faces/GIS.png',
      position: '',
      details1: 'Emergency: 0860 66 3473',
      details2: 'Office: 013 752 6419',
      email: 'gis@mufpa.co.za'
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
