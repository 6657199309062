import {Component} from '@angular/core';

@Component({
  selector: 'app-organogram',
  templateUrl: './organogram.component.html',
  styleUrls: ['./organogram.component.css']
})
export class OrganogramComponent {
  nodes: any = [
    {
      'name': 'MUFPA',
      'cssClass': 'ngx-org-ceo',
      'childs': [
        {
          'name': 'Ehlanzeni District Municipality',
          'cssClass': 'ngx-org-head',
          'title': '',
          'childs': [{'name': 'Greater Kruger FPA, Lowveld & Escarpment FPA'}]
        },
        {
          'name': 'Gert Sibande District Municipality',
          'cssClass': 'ngx-org-head',
          'title': '',
          'childs': [{'name': 'Dipaleseng FPA, Highveld East FPA, Lekwa FPA, Mkhondo FPA, Seme FPA, Msukaligwa FPA, Umpiluzi FPA'}]
        },
        {
          'name': 'Nkangala  District Municipality',
          'cssClass': 'ngx-org-head',
          'title': '',
          'childs': [{'name': 'Delmas FPA, Dr JS Moroka FPA, Thembisile Hani FPA, Greater Middleburg FPA, Greater Witbank FPA, Platorand Area FPA'}]
        }
      ]
    }

  ];
}
